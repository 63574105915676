<!-- 入库 -->
<template>
  <div class="pagePadding">
    <template v-if="!disblesImg">
      <div class="pageTop">
        <Form inline class="formMarginBtm20">
          <!-- 入库仓库 -->
          <!--        <FormItem>-->
          <!--          <span>入库仓库：</span>-->
          <!--          <Select class="iviewIptWidth250" v-model="warehouse_id" filterable clearable>-->
          <!--            <Option v-for="item in storeList" :value="item.warehouse_id" :key="item.warehouse_id">{{ item.warehouse_name }}</Option>-->
          <!--          </Select>-->
          <!--        </FormItem>-->
          <!-- 按钮 -->
          <FormItem>
            <span>收货单号：</span>
            <i-input id="sstj1" placeholder="请输入" class="iviewIptWidth250 marginRight60" v-model="queryFrom.order_number"></i-input>
          </FormItem>
          <FormItem>
            <span>收货时间：</span>
            <DatePicker id="sstj2" :editable="false" type="daterange" placement="bottom-end" placeholder="请选择" format="yyyy-MM-dd" class="iviewIptWidth250 marginRight60" @on-change="changeTime"></DatePicker>
          </FormItem>
          <FormItem>
            <span>供应商：</span>
            <Select id="sstj3" class="iviewIptWidth250" filterable clearable v-model="queryFrom.supplier_id">
              <Option v-for="(item, index) in supplierList" :value="item.supplier_id" :key="item.supplier_id + index">{{ item.supplier_name }}</Option>
            </Select>
          </FormItem>
          <!--          <FormItem class="button" :label-width="0">-->
          <!--            <span class="pageBtn finger btnSure" @click="query">查询</span>-->
          <!--          </FormItem>-->
          <FormItem class="button" :label-width="10">
            <span id="glshd_btn" class="longTextBtn finger btnAdd" @click="query">关联收货单</span>
            <span id="dtbt_sz" class="pageBtn finger btnSure marginLeft20" @click="setupStatus = true">设置</span>
          </FormItem>
        </Form>
        <TableCom :productList="listColumns" :productData="listData" :option_page="option_page" border :style="{ 'min-height': listData.length == 0 ? '0px' : '500px' }">
          <template slot-scope="{ row, index }" slot="bar_code">
            <i-input :id="'bar_code' + index" readonly placeholder="" :value="row.bar_code" @on-change="changeIpt($event, 'bar_code', index)"></i-input>
          </template>
          <template slot-scope="{ row, index }" slot="batch_number">
            <i-input :id="'batch_number' + index" readonly placeholder="" :value="row.batch_number" @on-change="changeIpt($event, 'batch_number', index)"></i-input>
          </template>
          <template slot-scope="{ row, index }" slot="serial_number">
            <i-input :id="'serial_number' + index" readonly placeholder="" :value="row.serial_number" @on-change="changeIpt($event, 'serial_number', index)"></i-input>
          </template>
          <template slot-scope="{ row, index }" slot="quantity">
            <!--          <i-input placeholder="请输入" type="number" :value="row.quantity" @on-change="changeIpt1($event, 'quantity', index)"></i-input>-->
            <InputNumber :id="'quantity' + index" :max="999999" :min="0" :precision="0" v-model="row.quantity" :active-change="false" @on-change="changeIpt1($event, 'quantity', index)"></InputNumber>
          </template>
          <template slot-scope="{ row, index }" slot="productionDate">
            <DatePicker :id="'productionDate' + index" readonly :editable="false" placeholder="请选择" type="date" format="yyyy-MM-dd" :value="row.production_date" @on-change="timeChange($event, index, 'production_date')"></DatePicker>
          </template>
          <template slot-scope="{ row, index }" slot="validPeriod">
            <DatePicker :id="'validPeriod' + index" readonly :editable="false" placeholder="请选择" type="date" format="yyyy-MM-dd" :value="row.valid_period" @on-change="timeChange($event, index, 'valid_period')"></DatePicker>
          </template>
        </TableCom>
        <div class="pages" v-if="listData !== null && listData.length > 0 && total">
          <Page id="fx1" v-if="pages && total <= 60" :total="total" :current="pages.page" @on-change="changePages" class="fl" />
          <Page id="fx2" v-if="pages && total > 60" :total="total" :current="pages.page" @on-change="changePages" show-elevator class="fl" />
          <div class="fr totalFont" v-if="total">共计{{ total }}条记录</div>
        </div>
        <!-- <div style="position: relative; border: 1px solid #e8eaec; height: 500px; border-top: none !important;" class="clearfix noData" v-if="listData.length == 0">
        <img style="margin-top: 100px;" src="../../../assets/images/tableNoData.png" />
      </div> -->
        <div class="tipsDiv">
          <div class="tipsFl">备注</div>
          <div class="tipsFr">
            <i-input id="bz_input" type="textarea" maxlength="100" show-word-limit placeholder="请输入" v-model="postFrom.note"></i-input>
          </div>
        </div>
      </div>
      <div class="pageBtm">
        <span id="qrrk_btn" class="longTextBtn finger btnSure marginLeft20" @click="post">确认入库</span>
      </div>
    </template>

    <div class="disableImage" v-if="disblesImg">
      <div class="disableDiv">
        <img src="../../../assets/images/disableImg.png" alt="" />
        <span>当月已关账，不可操作库存</span>
      </div>
    </div>
    <!-- 收货单 -->
    <Modal v-model="purchase" :mask-closable="false" class="modal" width="80">
      <p slot="header" class="header">收货单</p>
      <div class="body">
        <table-com class="table" @on-selection-change="changeSelection" :productList="purchaseColumns" :productData="purchaseData" :total="total" :pages="queryFrom" @change-page="changePage" totalText="条记录"></table-com>
      </div>
      <div slot="footer">
        <span class="longTextBtn finger btnSure marginLeft20" @click="chooseOrderWhole">确定</span>
      </div>
    </Modal>
    <!--    删除产品-->
    <tips-component showModal="delete" v-if="deleteStatus" @cancleBtn="deleteStatus = false" @sureBrn="sureDelete"></tips-component>
    <!--    提交-->
    <tips-component showModal="post" v-if="postStatus" @cancleBtn="postStatus = false" @sureBrn="lastPost"></tips-component>

    <!-- 动态表头弹窗 -->
    <table-setup v-if="setupStatus" :pageList="titleList" :option_page="option_page" @cancleBtn="setupStatus = false" @sureBrn="sureSetup"></table-setup>
  </div>
</template>

<script>
import TableSetup from '@/components/tableSetup'
import TableCom from '@/components/table'
import TipsComponent from '@/components/tipsComponent'
export default {
  name: 'enterStorageConsign',
  components: {
    TableCom,
    TipsComponent,
    TableSetup,
  },
  inject: ['reload'],
  data() {
    return {
      disblesImg: false,
      titleList: [],
      option_page: '14',
      setupStatus: false,
      postStatus: false,
      deleteStatus: false,
      supplierList: [],
      purchase: false,
      purchaseColumns: [
        {
          type: 'selection',
          width: 60,
          align: 'center',
        },
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 65,
        },
        {
          title: '收货时间',
          key: 'createTime',
          align: 'center',
          minWidth: 120,
        },
        {
          title: '收货单号',
          key: 'order_number',
          align: 'center',
          minWidth: 120,
        },
        {
          title: '产品',
          key: 'product_name',
          align: 'center',
          minWidth: 180,
        },
        {
          title: '数量',
          key: 'total_number',
          align: 'center',
          minWidth: 100,
          maxWidth: 130,
        },
        {
          title: '金额',
          key: 'total_amount',
          align: 'center',
          minWidth: 100,
          maxWidth: 130,
          render: (h, param) => {
            return h('span', this.$utils.formatMoney(param.row.total_amount, true))
          },
        },
        {
          title: '供应商',
          key: 'supplier_name',
          align: 'center',
          minWidth: 150,
        },
        // {
        //   title: '操作',
        //   align: 'center',
        //   width: 120,
        //   render: (h, param) => {
        //     return h('div', [
        //       h(
        //         'span',
        //         {
        //           style: {
        //             color: '#389AFC',

        //             cursor: 'pointer',
        //           },
        //           class: 'glshd_qr' + param.index,
        //           on: {
        //             click: () => {
        //               // 表格展示
        //               // this.chooseOrder(param.row)
        //               // 全部信息
        //               this.chooseOrderWhole(param.row)
        //             },
        //           },
        //         },
        //         '确认'
        //       ),
        //     ])
        //   },
        // },
      ],
      purchaseData: [],
      listData: [],
      listColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 60,
        },
        {
          title: '产品名称',
          key: 'product_name',
          align: 'center',
          minWidth: 200,
        },
        {
          title: '规格型号',
          key: 'model_name',
          align: 'center',
          width: 120,
        },
        {
          title: '条形码',
          slot: 'bar_code',
          align: 'center',
          width: 265,
        },
        {
          title: '批号',
          slot: 'batch_number',
          align: 'center',
          width: 150,
        },
        {
          title: '序列号',
          slot: 'serial_number',
          align: 'center',
          width: 150,
        },
        {
          title: '数量',
          slot: 'quantity',
          align: 'center',
          width: 115,
        },
        {
          title: '单位',
          key: 'unit',
          align: 'center',
          width: 95,
        },
        {
          title: '采购单价',
          align: 'center',
          width: 135,
          render: (h, param) => {
            return h('div', [h('span', param.row.unit_price ? '¥' + param.row.unit_price : 0)])
          },
        },
        {
          title: '生产日期',
          slot: 'productionDate',
          align: 'center',
          width: 150,
        },
        {
          title: '有效期',
          slot: 'validPeriod',
          align: 'center',
          width: 150,
        },
        {
          title: '操作',
          align: 'center',
          width: 128,
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  style: {
                    color: '#389AFC',

                    cursor: 'pointer',
                  },
                  on: {
                    click: () => {
                      this.deleteRow(param.index)
                    },
                  },
                },
                '删除'
              ),
            ])
          },
        },
      ],
      storeList: [],
      warehouse_id: '',
      queryFrom: {
        warehouse_id: '',
        page: 1,
        rows: 10,
        supplier_id: '',
        create_time_start: '',
        create_time_end: '',
        related_status: '1',
      },
      selectInfo: {}, // 选择的数据
      deleteIndex: 0,
      total: 0,
      pages: {
        page: 1,
        rows: 10,
      },
      row: null,
      postFrom: {
        warehouse_id: '',
        warehouse_name: '',
        note: '',
        line_info: [],
        supplier_id: '',
        supplier_name: '',
      },
      flag: true,
      wholeListData: [],
      changeSelectionList: [],
      id_list: [],
    }
  },
  mounted() {
    this.queryOption()
    this.queryStoreList()
    this.querySupplierSelect()
    this.inventoryLook()
  },
  activated() {
    this.inventoryLook()
  },
  methods: {
    changeSelection(e) {
      if (e) {
        this.changeSelectionList = e
      } else {
        this.changeSelectionList = []
      }
    },
    // 新增界面禁用功能
    inventoryLook() {
      this.$http.get(this.$apiConsign.inventoryLook, {}).then(res => {
        if (res.data === 1) {
          this.disblesImg = true
        }
      })
    },
    sureSetup(val) {
      this.queryOption()
    },
    // 获取表格表头
    queryOption() {
      this.$http
        .get(this.$apiConsign.systemOptionItem, { option_page: this.option_page })
        .then(res => {
          this.titleList = res.data.enable
          localStorage.setItem('adminTtitleList', JSON.stringify(res.data.list))
          for (let i = 0; i < this.titleList.length; i++) {
            if (this.titleList[i].show) {
              this.titleList[i].key = this.titleList[i].data_col
              this.titleList[i].width = this.titleList[i].column_width
              this.titleList[i].align = 'center'
              this.titleList[i].resizable = true
            }
          }
          this.listColumns = []
          this.listColumns.unshift({
            title: '序号',
            type: 'index',
            align: 'center',
            width: 60,
          })
          this.listColumns = [...this.listColumns, ...this.titleList]
          this.listColumns = JSON.parse(JSON.stringify(this.listColumns))
        })
        .then(() => {
          // this.queryStoreList()
        })
    },
    timeChange(e, index, name) {
      if (name == 'production_date') {
        this.$set(this.listData[index], name, e)
        // this.$set(this.listData[index], 'valid_period', null)
        let that = this
        let option = {
          disabledDate(date) {
            return date && date.valueOf() < new Date(that.listData[index].production_date) - 86400000
          },
        }
        // that.$set(this.listData[index], 'options', option)
      } else {
        this.$set(this.listData[index], name, e)
      }
    },
    // 确认入库
    post() {
      if (this.listData.length == 0) {
        this.$Message.warning('请至少添加一组产品再提交')
        return null
      }
      for (let i = 0; i < this.listData.length; i++) {
        this.listData[i].unit_price = this.listData[i].unit_price + '' // 第二次点击substring失效
        this.listData[i].unit_price = this.listData[i].unit_price ? this.listData[i].unit_price.substring(1, this.listData[i].unit_price.length) : 0
        this.listData[i].unit_price = this.listData[i].unit_price ? this.listData[i].unit_price.replace(/,/g, '') * 1 : 0
        if (!this.listData[i].quantity || !this.listData[i].valid_period) {
          this.$Message.warning(`请完善第${i + 1}组产品的数量/有效期再提交`)
          return
        }
      }
      this.postStatus = true
    },
    lastPost() {
      if (!this.flag) return
      this.flag = false
      let data = {
        id_list: this.id_list,
        note: this.postFrom.note,
      }
      this.$http
        .post(this.$apiConsign.warehousRreceiptBatch, data, true)
        .then(res => {
          this.flag = true
          this.$Message.success('入库成功')
          this.postStatus = false
          this.postFrom.note = ''
          this.reload()
          this.listData = []
        })
        .catch(err => {
          this.flag = true
        })
    },
    // 输入框改变
    changeIpt(e, name, index) {
      this.$set(this.listData[index], name, e.target.value.trim())
    },
    changeIpt1(e, name, index) {
      this.$set(this.listData[index], name, e)
    },
    // 确认删除
    sureDelete() {
      this.listData.splice(this.deleteIndex, 1)
      this.deleteStatus = false
      this.$Message.success('删除成功')
    },
    // 删除
    deleteRow(index) {
      this.deleteIndex = index
      this.deleteStatus = true
    },
    // 入库全部信息
    chooseOrderWhole() {
      this.id_list = []
      if (!this.changeSelectionList.length) {
        this.$Message.warning('请至少选择一组产品')
        return
      }
      this.changeSelectionList.forEach((item, index) => {
        this.id_list.push(item.id)
      })
      let objData = {
        id_list: this.id_list,
      }
      this.$http.post(this.$apiConsign.acceptanceLine, objData, false).then(res => {
        this.listData = res.data
        this.total = res.total
        // 处理时间
        this.listData.forEach((item, index) => {
          item.product_unit_price = item.unit_price
          item.licence_valid_period = item.licence_valid_period ? this.$moment.unix(item.licence_valid_period).format('YYYY-MM-DD') : ''
          item.production_date = item.production_date ? this.$moment.unix(item.production_date).format('YYYY-MM-DD HH:mm:ss') : null
          // 判断是录入的是年月还是年月日
          item.production_date = item.production_date ? this.$utils.Timetransformation(item.production_date).replace(/-/gi, '') : null
          item.valid_period = item.valid_period ? this.$moment.unix(item.valid_period).format('YYYY-MM-DD HH:mm:ss') : null
          // 判断是录入的是年月还是年月日
          item.valid_period = item.valid_period ? this.$utils.Timetransformation(item.valid_period).replace(/-/gi, '') : null
          item.production_license_valid_period = item.production_license_valid_period ? this.$moment.unix(item.production_license_valid_period).format('YYYY-MM-DD') : ''
          item.production_record_date = item.production_record_date ? this.$moment.unix(item.production_record_date).format('YYYY-MM-DD') : ''
          if (item.business_license_valid_period && this.$moment.unix(item.business_license_valid_period).format('YYYY-MM-DD') != '9999-12-31') {
            item.business_license_valid_period = item.business_license_valid_period ? this.$moment.unix(item.business_license_valid_period).format('YYYY-MM-DD') : ''
          } else if (item.business_license_valid_period && this.$moment.unix(item.business_license_valid_period).format('YYYY-MM-DD') == '9999-12-31') {
            item.business_license_valid_period = '长期'
          } else {
            item.business_license_valid_period = ''
          }
          if (item.record_valid_period && this.$moment.unix(item.record_valid_period).format('YYYY-MM-DD') != '9999-12-31') {
            item.record_valid_period = item.record_valid_period ? this.$moment.unix(item.record_valid_period).format('YYYY-MM-DD') : ''
          } else if (item.record_valid_period && this.$moment.unix(item.record_valid_period).format('YYYY-MM-DD') == '9999-12-31') {
            item.record_valid_period = '长期'
          } else {
            item.record_valid_period = ''
          }
          item.return_time = item.return_time ? this.$moment.unix(item.return_time).format('YYYY-MM-DD') : ''
          item.receive_time = item.receive_time ? this.$moment.unix(item.receive_time).format('YYYY-MM-DD') : ''
          item.accept_time = item.accept_time ? this.$moment.unix(item.accept_time).format('YYYY-MM-DD') : ''
          item.rec_time = item.rec_time ? this.$moment.unix(item.rec_time).format('YYYY-MM-DD') : ''
          item.order_time = item.order_time ? this.$moment.unix(item.order_time).format('YYYY-MM-DD') : ''
          item.picking_time = item.picking_time ? this.$moment.unix(item.picking_time).format('YYYY-MM-DD') : ''
          item.delivery_time = item.delivery_time ? this.$moment.unix(item.delivery_time).format('YYYY-MM-DD') : ''
          for (let i in item) {
            if (i == 'unit_price' || i == 'product_unit_price') {
              item[i] = this.OpearateMoney(item[i])
            }
          }
        })
        this.purchase = false
      })
    },
    // 确认选择
    chooseOrder(row) {
      this.postFrom.warehouse_id = row.warehouse_id
      this.postFrom.warehouse_name = row.warehouse_name
      this.postFrom.supplier_id = row.supplier_id
      this.postFrom.supplier_name = row.supplier_name
      this.row = row
      let obj = {
        id: row.id,
        receipt_page: '1',
        page: this.pages.page,
        rows: this.pages.rows,
      }
      this.$http.get(this.$apiConsign.acceptanceManageDetail, obj, false).then(res => {
        this.listData = res.data
        this.total = res.total
        // 处理时间
        this.listData.forEach((item, index) => {
          item.product_unit_price = item.unit_price
          item.licence_valid_period = item.licence_valid_period ? this.$moment.unix(item.licence_valid_period).format('YYYY-MM-DD') : ''
          item.production_date = item.production_date ? this.$moment.unix(item.production_date).format('YYYY-MM-DD HH:mm:ss') : null
          // 判断是录入的是年月还是年月日
          item.production_date = item.production_date ? this.$utils.Timetransformation(item.production_date).replace(/-/gi, '') : null
          item.valid_period = item.valid_period ? this.$moment.unix(item.valid_period).format('YYYY-MM-DD HH:mm:ss') : null
          // 判断是录入的是年月还是年月日
          item.valid_period = item.valid_period ? this.$utils.Timetransformation(item.valid_period).replace(/-/gi, '') : null
          item.production_license_valid_period = item.production_license_valid_period ? this.$moment.unix(item.production_license_valid_period).format('YYYY-MM-DD') : ''
          item.production_record_date = item.production_record_date ? this.$moment.unix(item.production_record_date).format('YYYY-MM-DD') : ''
          if (item.business_license_valid_period && this.$moment.unix(item.business_license_valid_period).format('YYYY-MM-DD') != '9999-12-31') {
            item.business_license_valid_period = item.business_license_valid_period ? this.$moment.unix(item.business_license_valid_period).format('YYYY-MM-DD') : ''
          } else if (item.business_license_valid_period && this.$moment.unix(item.business_license_valid_period).format('YYYY-MM-DD') == '9999-12-31') {
            item.business_license_valid_period = '长期'
          } else {
            item.business_license_valid_period = ''
          }
          if (item.record_valid_period && this.$moment.unix(item.record_valid_period).format('YYYY-MM-DD') != '9999-12-31') {
            item.record_valid_period = item.record_valid_period ? this.$moment.unix(item.record_valid_period).format('YYYY-MM-DD') : ''
          } else if (item.record_valid_period && this.$moment.unix(item.record_valid_period).format('YYYY-MM-DD') == '9999-12-31') {
            item.record_valid_period = '长期'
          } else {
            item.record_valid_period = ''
          }
          item.return_time = item.return_time ? this.$moment.unix(item.return_time).format('YYYY-MM-DD') : ''
          item.receive_time = item.receive_time ? this.$moment.unix(item.receive_time).format('YYYY-MM-DD') : ''
          item.accept_time = item.accept_time ? this.$moment.unix(item.accept_time).format('YYYY-MM-DD') : ''
          item.rec_time = item.rec_time ? this.$moment.unix(item.rec_time).format('YYYY-MM-DD') : ''
          item.order_time = item.order_time ? this.$moment.unix(item.order_time).format('YYYY-MM-DD') : ''
          item.picking_time = item.picking_time ? this.$moment.unix(item.picking_time).format('YYYY-MM-DD') : ''
          item.delivery_time = item.delivery_time ? this.$moment.unix(item.delivery_time).format('YYYY-MM-DD') : ''
          for (let i in item) {
            if (i == 'unit_price' || i == 'product_unit_price') {
              item[i] = this.OpearateMoney(item[i])
            }
          }
        })
        // for (let i = 0; i < this.listData.length; i++) {
        //   this.listData[i].valid_period = this.listData[i].valid_period ? this.$moment(this.listData[i].valid_period * 1000).format('YYYY-MM-DD') : ''
        //   this.listData[i].production_date = this.listData[i].production_date ? this.$moment(this.listData[i].production_date * 1000).format('YYYY-MM-DD') : ''
        // }
        this.purchase = false
      })
    },
    // 处理金额
    OpearateMoney(item) {
      if (!item) {
        return 0
      }
      let num = item
      num = parseFloat((num + '').replace(/[^\d\.-]/g, '')).toFixed(2) + ''
      var valueArr = num.split('.')[0].split('').reverse()
      const valueFloat = num.split('.')[1]
      let valueString = ''
      for (let i = 0; i < valueArr.length; i++) {
        valueString += valueArr[i] + ((i + 1) % 3 == 0 && i + 1 != valueArr.length ? ',' : '') // 循环 取数值并在每三位加个','
      }
      const money = valueString.split('').reverse().join('') + '.' + valueFloat // 拼接上小数位
      return '¥' + money
    },
    // 时间改变
    changeTime(e) {
      if (e[0] == '' && e[1] == '') {
        this.queryFrom.create_time_start = null
        this.queryFrom.create_time_end = null
      } else {
        this.queryFrom.create_time_start = e[0]
        this.queryFrom.create_time_end = e[1]
      }
    },
    // 关联收货单
    addPurchase() {
      // this.queryFrom.warehouse_id = this.warehouse_id
      this.$http.get(this.$apiConsign.acceptanceManage, this.queryFrom, true).then(res => {
        this.purchaseData = res.data
        this.total = res.total
        for (let i = 0; i < this.purchaseData.length; i++) {
          this.purchaseData[i].createTime = this.purchaseData[i].create_time ? this.$moment(this.purchaseData[i].create_time * 1000).format('YYYY-MM-DD HH:mm:ss') : ''
        }
        this.purchase = true
      })
    },
    // 查询仓库列表
    queryStoreList() {
      this.isLoad = true
      let data = {
        warehouse_name: '',
        page: 1,
        rows: 1000,
        is_consign:1
      }
      this.$http.get(this.$apiConsign.permissionWarehouseList, data, true).then(res => {
        this.storeList = res.data
      })
    },
    // 供应商名称
    querySupplierSelect() {
      this.$http.fetch(this.$apiConsign.supplierSelect, true).then(res => {
        this.supplierList = res.data
      })
    },
    changePages(e) {
      this.pages.page = e
      // this.chooseOrder(this.row)
      // this.chooseOrderWhole()
      this.addPurchase()
    },
    changePage(e) {
      this.queryFrom.page = e
      this.addPurchase()
    },
    query() {
      this.queryFrom.page = 1
      this.addPurchase()
    },
  },
}
</script>

<style scoped lang="less">
.pagePadding {
  display: flex;
  flex-direction: column;
  .pageTop {
    flex: 1;
    overflow-y: auto;
    .tipsDiv {
      margin-top: 20px;
      border: 1px solid #e8eaec;
      height: 230px;
      display: flex;
      .tipsFl {
        width: 210px;
        line-height: 230px;
        padding-left: 33px;
        border-right: 1px solid #e8eaec;
        background: #f8f8f9;
        color: #525b6d;
        font-size: 14px;
      }
      /deep/ .tipsFr {
        flex: 1;
        display: flex;
        padding: 8px 15px 8px 23px;
        .ivu-input-wrapper {
          height: 100% !important;
        }
        .ivu-input {
          height: 100% !important;
        }
      }
    }
  }

  .pageBtm {
    text-align: right;
    padding-top: 25px;
  }
}
.marginRight60 {
  margin-right: 60px;
}
.modal {
  // /deep/ .ivu-modal {
  //   width: 1050px !important;
  // }
  /deep/ .ivu-modal-footer {
    border: 0;
  }

  .header {
    font-size: 18px;
    height: 25px;
    line-height: 25px;
    font-weight: 500;
    color: #333333;
  }

  .body {
    .iviewIptWidth150 {
      width: 150px !important;
    }
    .iviewIptWidth200 {
      width: 200px !important;
    }
    .marginRight23 {
      margin-right: 23px;
    }
  }
}
</style>
